<template>
  <footer class="bg-gray-800 text-gray-400 py-8">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap justify-between items-start mb-6 px-10 space-y-4 md:space-y-0">
       
       
         <!-- Mission Section -->
         <div class="flex flex-col items-start  w-full md:w-1/3 mb-4 md:mb-0">
          <h3 class="text-xl font-bold mb-2"><span class="text-3xl">Husky Club</span></h3>
          <p>Our Mission: Welcome to our website! Our website operate as non-profit with a mission to empower parents and children through educational resources and opportunities. Join us in our cause!</p>
        </div>
               <!-- Contact Information -->
         <div class="flex flex-col items-start w-full md:w-1/3 mb-4 md:mb-0">
          <h3 class="text-xl font-bold mb-2">Contact Us</h3>
          <p>Katy, Texas</p>
          <p>Email: info@myhuskyclub.com</p> <br>
          <h3 class="text-xl font-bold mb-2">Follow Us</h3>
          <div class="flex space-x-4">
            <a href="#" target="_blank" class="text-lg"><i class="fab fa-facebook"></i></a>
            <a href="#" target="_blank" class="text-lg"><i class="fab fa-instagram"></i></a>
            <a href="#" target="_blank" class="text-lg"><i class="fab fa-twitter"></i></a>
            <a href="#" target="_blank" class="text-lg"><i class="fab fa-youtube"></i></a>
          </div>
        </div>
      </div>
      <!-- Navigation Links -->
      <nav class="flex flex-wrap justify-center space-x-4 border-t pt-4 border-gray-600">
        <a href="/index.html" class="my-1">Home</a>
        <a href="/classes-about.html" class="my-1">Classes</a>
        <a href="/exams.html" class="my-1">Exams</a>
        <a href="/events.html" class="my-1">Events</a>
        <a href="/husky-club.html" class="my-1">Husky Club</a>
        <a href="/about.html" class="my-1">About</a>
      </nav>
      <div class="mt-4 text-center text-sm">
        <p>&copy; {{ new Date().getFullYear() }} Husky Club. All Rights Reserved.</p>
      </div>
    </div>
  </footer>
</template>

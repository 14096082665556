<template>
  <div class="width-full bg-guo-bg-green border-b-2 border-b-guo-dark-gold ">
    <div class="container mx-auto px-4 font-sans">
      <!-- Hamburger menu button for small screens -->
      <div class="md:hidden">
        <button @click.stop="toggleMobileMenu" class="focus:outline-none md:w-full font-bold text-lg text-guo-bg-text">
          <div class="float-right pt-20">导航 ≡ </div>
          <img src="@/assets/image/logo.png" alt="Logo" class="float-left" />
        </button>
      </div>
      <nav :class="{ 'block w-full text-right md:hidden': mobileMenuOpen, 'hidden': !mobileMenuOpen }">
        <ul class="block w-full pr-4">
          <li class="py-2 border-b-2 border-b-guo-dark-gold">
            <a href="/index.html" class="text-guo-bg-text  text-lg" active-class="font-semibold">
              首页
            </a>
          </li>
          <li class="py-2 border-b-2 border-b-guo-dark-gold">
            <a href="/classes-about.html" class="text-guo-bg-text  text-lg" active-class="font-semibold">
              科目
            </a>
          </li>
          <li class="py-2 border-b-2 border-b-guo-dark-gold">
            <a href="/classes-intro.html" class="text-guo-bg-text  text-lg" active-class="font-semibold">
              亲子活动
            </a>
          </li>
          <li class="py-2 border-b-2 border-b-guo-dark-gold">
            <a href="/exams.html" class="text-guo-bg-text  text-lg" active-class="font-semibold">
              竞赛与考试
            </a>
          </li>
          <!-- 
      <li class="py-2 border-b-2 border-b-guo-dark-gold">
        <a href="/resources.html" class="text-guo-bg-text  text-lg" active-class="font-semibold">
          资源
        </a>
      </li>
      -->
          <li class="py-2 border-b-2 border-b-guo-dark-gold">
            <a href="/events.html" class="text-guo-bg-text  text-lg" active-class="font-semibold">
              Katy资源
            </a>
          </li>
          <li class="py-2 border-b-2 border-b-guo-dark-gold">
            <a href="/husky-club.html" class="text-guo-bg-text  text-lg" active-class="font-semibold">
              哈士奇俱乐部
            </a>
          </li>

          <li class="py-2 border-b-2 border-b-guo-dark-gold">
            <a href="/about.html" class="text-guo-bg-text  text-lg" active-class="font-semibold">
              关于我们
            </a>
          </li>

        </ul>
      </nav>



      <div class="flex justify-between items-center py-4">
        <div class="hidden md:flex items-center justify-center">
          <a href="/index.html">
            <img src="@/assets/image/logo.png" alt="Logo" class=" mr-4" />
          </a>
          <!-- <h1 class="text-4xl font-bold text-guo-light-gold">玩哈学社</h1> -->
        </div>


        <nav class="hidden md:block p-4 z-20 ">
          <ul class="flex">
            <li class="mr-6">
              <a href="/index.html" class="text-guo-bg-text hover:text-gray-200 text-lg" active-class="font-semibold">
                首页
              </a>
            </li>
            <li class="mr-6">
              <a href="/classes-about.html" class="text-guo-bg-text hover:text-gray-200 text-lg" active-class="font-semibold">
                科目
              </a>
            </li>
            <!--
            <li class="mr-6">
              <a href="/classes-intro.html" class="text-guo-bg-text hover:text-gray-200 text-lg" active-class="font-semibold">
                亲子活动
              </a>
            </li>
-->

            <li class="mr-6">
              <a href="/exams.html" class="text-guo-bg-text hover:text-gray-200 text-lg" active-class="font-semibold">
                竞赛与考试
              </a>
            </li>
            <!-- 
            <li class="mr-6">
              <a href="/resources.html" class="text-guo-bg-text hover:text-gray-200 text-lg" active-class="font-semibold">
                资源
              </a>
            </li>
            -->
            <li class="mr-6">
              <a href="/events.html" class="text-guo-bg-text hover:text-gray-200 text-lg" active-class="font-semibold">
                Katy资源
              </a>
            </li>
            <li class="mr-6">
              <a href="/husky-club.html" class="text-guo-bg-text hover:text-gray-200 text-lg"
                active-class="font-semibold">
                哈士奇俱乐部
              </a>
            </li>

            <li class="mr-6">
              <a href="/about.html" class="text-guo-bg-text hover:text-gray-200 text-lg" active-class="font-semibold">
                关于我们
              </a>
            </li>

          </ul>
        </nav>



      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dropdownOpen: false,
      mobileMenuOpen: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
    closeMenus() {
      if (this.mobileMenuOpen) {
        this.mobileMenuOpen = false;
      }
      if (this.dropdownOpen) {
        this.dropdownOpen = false;
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.closeMenus);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeMenus);
  },
};
</script>
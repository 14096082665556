<template>
  <div id="app">
    <NavigationMenu />

    <div class="">
      <!-- Add your hero content, headline, and call-to-action button here -->
      <div class="relative bg-center min-h-screen bg-no-repeat bg-cover "
        :style="`background-image: url(${imageUrl});background-attachment: fixed;`">
        <div class="absolute inset-0 bg-black opacity-30 z-1"></div>
        <div class="flex flex-col justify-center  items-center min-h-screen">
          <!-- feature of the website -->
          <div
            class="relative flex flex-col md:grid md:grid-cols-2 gap-0 px-4 pt-4 md:px-8 md:pt-8 z-2 justify-center max-w-6xl">
            <h1 class="text-white text-4xl md:text-6xl opacity-80 my-10 md:my-20 text-center md:text-right -mr-10">玩耍是天性，
            </h1>
            <h1 class="text-white text-4xl md:text-6xl opacity-80 my-10 md:my-20 text-center md:text-left ml-10 "> 学习也是
            </h1>

            <!-- Card 1 -->
            <div
              class="flex space-x-4  bg-guo-tea-green border-2 border-guo-tea-dark-green text-white my-5 shadow-lg transform transition-transform duration-500 ease-in-out hover:scale-105">
              <div class="my-auto p-6 rounded-full">
                <h1 class="font-bold text-2xl md:text-3xl mb-4 text-guo-light-yellow !text-right ">亲子互动 🟡 </h1>
                <p>除了课外班，亲子互动也是孩子成长很重要的一个部分。在 <span class="text-guo-red">一起来学习 > 亲子互动</span> 部分，我们列出了有趣的亲子活动的创意。在 <span
                    class="text-guo-red">一起来学习 > 科目 </span>
                  里面的课程，尽量做得有趣，以引起小朋友兴趣为主。 希望父母与小朋友一起玩的开心，学得有趣。</p>
              </div>

            </div>

            <!-- Hover over effect -->
            <div
              class="flex space-x-4 opacity-80 bg-guo-tea-dark-green  text-white  shadow-lg  transform transition-transform duration-500 ease-in-out hover:scale-105">
              <img class="w-64 h-64 object-cover  border-2 border-guo-tea-dark-green"
                src="../../assets/image/huskyclassroom-homepage-seashell.jpg" alt="Image Description 1">
              <div class="my-auto text-guo-light-gold">
                <p>与孩子一起学习，一起成长。我们带领孩子一起学习探索，如同漫步海边，海风清澈，海浪追逐。我们一起学到的每一个知识，每一个规则，每一个技能，都该盈溢着捡到精美贝壳的惊喜。</p>
              </div>
            </div>
            <div><br></div>
            <div><br></div>
            <div><br></div>
            <div><br></div>

            <!-- Card 2 -->
            <!-- Hover over effect -->
            <div
              class="flex space-x-4 pl-4 opacity-80 bg-guo-dark-blue  text-white  shadow-lg  transform transition-transform duration-500 ease-in-out hover:scale-105">

              <div class="my-auto text-guo-light-blue">
                <p>作为家长，其实我们所求不多，无非是孩子完成一个个小目标时，心里的喜，脸上的笑，眼里的光。</p>
              </div>
              <img class="w-64 h-64 object-cover  border-2 border-guo-dark-blue"
                src="../../assets/image/huskyclassroom-topic-learning2.png" alt="Image Description 1">
            </div>
            <div
              class="flex space-x-4  bg-guo-light-blue border-2 border-guo-dark-blue text-white my-5 shadow-lg   transform transition-transform duration-500 ease-in-out hover:scale-105">

              <div class="my-auto p-6 rounded-full">
                <h1 class="font-bold text-3xl mb-4 text-guo-light-yellow  ">🔵 主题学习 </h1>
                <p>我们的课程是围绕一个个的主题开展。当我们发现小朋友的问题，或者哪个知识点薄弱，可以进行专项训练。 </p>
              </div>
            </div>
            <div><br></div>
            <div><br></div>
            <div><br></div>
            <div><br></div>

            <!-- Card 3 -->
            <div
              class="flex space-x-4  bg-guo-light-pink border-2 border-guo-brown text-white my-5 shadow-lg  transform transition-transform duration-500 ease-in-out hover:scale-105">

              <div class="my-auto p-6 rounded-full">
                <h1 class="font-bold text-3xl mb-4 text-guo-light-yellow !text-right "> 适合小学生 🟠</h1>
                <p> 网站内容随着时间慢慢添加，希望网站跟孩子们一起成长。</p>
              </div>
            </div>

            <!-- Hover over effect -->
            <div
              class="flex space-x-4 opacity-80 bg-guo-brown  text-white  shadow-lg  transform transition-transform duration-500 ease-in-out hover:scale-105">
              <img class="w-64 h-64 object-cover  border-2 border-guo-brown"
                src="../../assets/image/huskyclassroom-topic-learning.jpg" alt="Image Description 1">
              <div class="my-auto text-guo-light-pink">
                <p>献给这群，无所思而无所不思，无所为而无所不为，无缘由又皆有缘由的孩子们，希望你们喜欢。</p>
              </div>
            </div>
            <div><br></div>
            <div><br></div>
            <div><br></div>
            <div><br></div>

            <!-- Card 4 -->
            <!-- Hover over effect -->
            <div
              class="flex space-x-4 pl-4 opacity-80 bg-guo-red  text-white  shadow-lg  transform transition-transform duration-500 ease-in-out hover:scale-105">

              <div class="my-auto text-guo-partner-red">
                <p>分享孩子如何应对挑战；分享我们如何在带娃的喜悦中，忘却营营；分享孩子的挑战作品以及我们如何发现自己家娃，不是人才，是天才啊！</p>
              </div>
              <img class="w-64 h-64 object-cover  border-2 border-guo-red"
                src="../../assets/image/huskyclassroom-topic-learning3.png" alt="Image Description 1">
            </div>
            <div
              class="flex space-x-4  bg-guo-partner-red border-2 border-guo-red text-white my-5 shadow-lg  transform transition-transform duration-500 ease-in-out hover:scale-105">

              <div class="my-auto p-6 rounded-full">
                <h1 class="font-bold text-3xl mb-4 text-guo-light-yellow  ">🔴 社区互动 </h1>
                <p>对我们的课程和挑战感兴趣的家长，可以加入我们的微信群，以及facebook群。答疑解惑，分享挑战作品，参加我们的活动，等等 </p>
              </div>
            </div>


          </div>

        </div>
        <div><br></div>
        <div><br></div>
        <div><br></div>
        <div><br></div>

        <!-- my letter -->
        <div class="relative flex flex-col justify-center items-center">

          <div
            class="bg-white border-4 border-guo-bg-green text-black rounded-2xl font-bold text-lg font-serif tracking-wide bg-opacity-70 px-8 py-6 mb-12 text-center ">

            <p class="py-2">虽然孩子还小，</p>
            <p class="py-2">但我希望他开眼看世界，</p>
            <p class="py-2">看万事万物是如何运行在规则之下。</p>
            <p class="py-2">我希望他开始使用自己的脑子，既可以玩转逻辑；</p>
            <p class="py-2">也可以放飞想象，天马行空，突破次元。</p>
            <p class="py-2">我希望他有鸿鹄志，有颗搏击一切阻碍的心；</p>
            <p class="py-2">亦有皎皎明眸，懂得自省，</p>
            <p class="py-2">看到自己的弱处，去练习，练习，练习；</p>
            <p class="py-2">还要有搬山蚂蚁的力量，</p>
            <p class="py-2">从一粒粒沙，积累成沙丘，成莽莽大山。</p>
            <br>
            <p class="py-2">古人云:</p>
            <p class="py-2">不闻大论，则志不宏；</p>
            <p class="py-2">不听至言，则心不固。</p>
            <p class="py-2">或许，我们可以请有识之士来给孩子们讲讲各行各业的故事？</p>
            <br>
            <p class="py-2">古人云:</p>
            <p class="py-2">举一纲而万目张，解一卷而众篇明。</p>
            <p class="py-2">或许，我们可以细心看到孩子在哪个知识点或者能力上薄弱，加强巩固一下？</p>
            <br>
            <p class="py-2">古人云:</p>
            <p class="py-2">独乐乐不如众乐乐。</p>
            <p class="py-2">或许，我们可以一起开心玩耍中学习？</p>
            <br>
            <p class="py-2">目前，我们的网站是非盈利性质的。是妈妈送给儿子和他朋友的礼物。 </p>
            <p class="py-2">但是，单丝不成线，独木不成林。</p>
            <p class="py-2">如果你是同样的父母，有相同的哈士奇二哈皮娃，有更多的想法和建议，告诉我们吧！</p>
            <p class="py-2">来自： 老哈士奇</p>

            <img :src="secondImage" class=" max-w-xs w-full mt-0 mx-auto block" alt="Image description">

          </div>
          <!-- feedback form-->
<!-- 
          <div   class="bg-we-dark-green text-white bg-opacity-50 rounded-lg p-8 flex flex-col  w-full max-w-xl mt-10 md:mt-0">
            <h2 class=" text-lg mb-1 font-medium title-font text-center ">告诉我们您的想法</h2>
            <div class="relative mb-4">
              <label for="full-name" class="leading-7 text-sm">称呼：</label>
              <input type="text" id="full-name" name="full-name"
                class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
            </div>
            <div class="relative mb-4">
              <label for="email" class="leading-7 text-sm">邮箱：</label>
              <input type="email" id="email" name="email"
                class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
            </div>
            <div class="relative mb-4">
              <label for="message" class="leading-7 text-sm">消息：</label>
              <textarea id="message" name="message"
                class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
            </div>
            <button
              class="text-white bg-btn-green border-0 py-2 px-6 focus:outline-none hover:bg-btn-yellow hover:text-btn-green rounded-2xl text-lg">提交</button>
          </div>
          -->
          <br>
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeV1y1CyRkR611DUPlkgxCX9zMEJNEzE8VJ3_h-YiiHxsD2Cw/viewform?embedded=true" width="640" height="900" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

          <!-- feedback form-->
        </div>
      </div>



      <!-- Add your hero content, headline, and call-to-action button here -->

    </div>

  </div>
  <Footer />
</template>

<script>
import NavigationMenu from '../../components/NavigationMenu.vue';
//import AnimatedBackground from "@/components/AnimatedBackground.vue";
import Footer from "@/components/FooterMenu.vue";

export default {
  name: 'App',
  components: {
    NavigationMenu,
    //AnimatedBackground,
    Footer,
  },
  data() {
    return {
      imageUrl: require('@/assets/image/huskyclassroom-hero-bg.jpg'),
      secondImage: require('@/assets/image/huskyclassroom-old.png'),

    }
  }
}
</script>

<style scoped></style>
